/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdd49a", endColorstr="#fdf59a", GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9 ;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f3c17a", endColorstr="#f3e97a", GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9 ;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: linear-gradient(to bottom, #b3b3b3, #808080);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b3b3b3", endColorstr="#808080", GradientType=0);
  border-color: #808080 #808080 #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9 ;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
  background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
  background-image: linear-gradient(to bottom, #08c, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#08c", endColorstr="#0044cc", GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #0044cc;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #003399 \9 ;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
  background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
  background-image: linear-gradient(to bottom, #08c, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#08c", endColorstr="#0044cc", GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #0044cc;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #003399 \9 ;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

/*# sourceMappingURL=bootstrap-datepicker.css.map */
.hs-input {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 1.25rem 0 1.25rem;
  font-family: "Kokoro", serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.13158;
  color: #000000;
  background-color: transparent;
  background-clip: padding-box;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 2px;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .hs-input {
    transition: none;
  }
}
@media (min-width: 768px) {
  .hs-input {
    padding: 1.25rem 0 1.25rem;
    height: 4.375rem;
  }
}
.hs-input::-ms-expand {
  background-color: transparent;
  border: 0;
}
.hs-input:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}
.hs-input:focus {
  color: #000000;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}
.hs-input::placeholder {
  color: #000000;
  opacity: 1;
}
.hs-input:disabled, .hs-input[readonly] {
  background-color: #C3CFAC;
  opacity: 1;
}
.hs-input:disabled {
  cursor: not-allowed;
}
.hs-input[type=file] {
  padding-top: 1.25rem;
}
@media (min-width: 768px) {
  .hs-input[type=file] {
    padding-top: 1.25rem;
  }
}

.hs-form-field {
  margin: 22.5px 0 22.5px;
  font-family: "GT America", sans-serif;
}
@media (min-width: 768px) {
  .hs-form-field {
    margin: 40px 0 40px;
  }
}
.hs-form-field.hs-fieldtype-checkbox, .hs-form-field.hs-fieldtype-booleancheckbox {
  margin: 0 0 50px;
}
.hs-form-field.hs-fieldtype-checkbox > label, .hs-form-field.hs-fieldtype-booleancheckbox > label {
  margin: 0 0 25px;
}

.hbspt-form fieldset.form-columns-0,
.hbspt-form fieldset.form-columns-1,
.hbspt-form fieldset.form-columns-2 {
  max-width: none;
}
.hbspt-form fieldset.form-columns-0 .hs-input,
.hbspt-form fieldset.form-columns-1 .hs-input,
.hbspt-form fieldset.form-columns-2 .hs-input {
  display: block;
  width: 100%;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}
.form-row .form-row-elements {
  display: flex;
  flex-wrap: wrap;
}

.was-validated .hs-input:valid, .hs-input.is-valid {
  padding-right: 0;
  background-image: none;
  background-repeat: no-repeat;
  background-position: right 0 center;
  background-size: 1rem 1rem;
  border-color: #000000;
}
@media (min-width: 768px) {
  .was-validated .hs-input:valid, .hs-input.is-valid {
    padding-right: 0;
    background-position: right 0 center;
  }
}
.was-validated .hs-input:valid:focus, .hs-input.is-valid:focus {
  box-shadow: none;
  border-color: #000000;
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  padding-right: calc(0.75em + 1.5625rem);
  background: transparent url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Theme/small-arrow-black.svg") no-repeat right;
  background-position-x: calc(100% - 11px);
  background-size: 12px;
  border-color: #000000;
}
.was-validated .custom-select:valid ~ label, .custom-select.is-valid ~ label {
  color: #28a745;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  box-shadow: none;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #000000;
}
.was-validated .custom-select:valid ~ label, .custom-select.is-valid ~ label {
  color: #000000;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #000000;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: transparent !important;
  border-color: #000000 !important;
  box-shadow: none !important;
}

.was-validated .hs-input:invalid, .hs-input.is-invalid {
  padding-right: 0;
  background-image: none;
  background-repeat: no-repeat;
  background-position: right 0 center;
  background-size: 1rem 1rem;
}
@media (min-width: 768px) {
  .was-validated .hs-input:invalid, .hs-input.is-invalid {
    padding-right: 0;
    background-position: right 0 center;
  }
}
.was-validated .hs-input:invalid:focus, .hs-input.is-invalid:focus {
  box-shadow: none;
  border-color: #000000;
}
.was-validated .hs-input:invalid ~ label, .hs-input.is-invalid ~ label {
  color: #b60202 !important;
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  padding-right: calc(0.75em + 1.5625rem);
  background: transparent url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Theme/small-arrow-black.svg") no-repeat right;
  background-position-x: calc(100% - 11px);
  background-size: 12px;
}
.was-validated .custom-select:invalid ~ label, .custom-select.is-invalid ~ label {
  color: #b60202;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  box-shadow: none;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #b60202;
}

.hs-fieldtype-select,
.hs-fieldtype-text {
  position: relative;
}
.hs-fieldtype-select label,
.hs-fieldtype-text label {
  line-height: 1.13158;
  font-family: "GT America", sans-serif;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  transition: all 0.15s ease-in-out;
  font-size: 0.75rem;
  top: 0;
  transform: translateY(-100%);
  color: #000000;
}
@media (min-width: 768px) {
  .hs-fieldtype-select label,
.hs-fieldtype-text label {
    left: 0;
  }
}
@media (min-width: 768px) {
  .hs-fieldtype-select label,
.hs-fieldtype-text label {
    font-size: 1.25rem;
  }
}

.inputs-list {
  padding: 0;
}
.inputs-list li {
  list-style: none;
}
.inputs-list.hs-error-msgs label,
.inputs-list.hs-error-msgs label a,
.inputs-list.hs-error-msgs .hs-error-msg {
  position: relative;
  transform: none;
  margin: 0.75rem 0;
  color: #b60202;
  font-size: 1rem;
  line-height: 1.25;
}
.inputs-list.hs-error-msgs label a:hover {
  cursor: pointer;
}

.hs-form-checkbox,
.hs-form-booleancheckbox {
  list-style: none;
}
.hs-form-checkbox .hs-form-checkbox-display,
.hs-form-checkbox .hs-form-booleancheckbox-display,
.hs-form-booleancheckbox .hs-form-checkbox-display,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display {
  position: relative;
  display: block;
  transform: none;
  z-index: 1;
}
.hs-form-checkbox .hs-form-checkbox-display .hs-input,
.hs-form-checkbox .hs-form-booleancheckbox-display .hs-input,
.hs-form-booleancheckbox .hs-form-checkbox-display .hs-input,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  z-index: -1;
}
.hs-form-checkbox .hs-form-checkbox-display > span,
.hs-form-checkbox .hs-form-booleancheckbox-display > span,
.hs-form-booleancheckbox .hs-form-checkbox-display > span,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display > span {
  cursor: pointer;
  user-select: none;
  position: relative;
  display: block;
  margin: 0;
  padding-left: 2.5rem;
  font-family: "Kokoro", serif;
  text-transform: none;
  font-size: 1.125rem;
}
.hs-form-checkbox .hs-form-checkbox-display > span::before,
.hs-form-checkbox .hs-form-booleancheckbox-display > span::before,
.hs-form-booleancheckbox .hs-form-checkbox-display > span::before,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display > span::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  margin-top: -0.75rem;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  border: solid 2px #000000;
  border-radius: 0;
}
.frame-background-dark .hs-form-checkbox .hs-form-checkbox-display > span::before,
.frame-background-dark .hs-form-checkbox .hs-form-booleancheckbox-display > span::before,
.frame-background-dark .hs-form-booleancheckbox .hs-form-checkbox-display > span::before,
.frame-background-dark .hs-form-booleancheckbox .hs-form-booleancheckbox-display > span::before {
  background-color: transparent;
}
.hs-form-checkbox .hs-form-checkbox-display > span::after,
.hs-form-checkbox .hs-form-booleancheckbox-display > span::after,
.hs-form-booleancheckbox .hs-form-checkbox-display > span::after,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display > span::after {
  position: absolute;
  display: none;
  content: "";
  top: 50%;
  margin-top: -7px;
  width: 6px;
  height: 12px;
  left: 9px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.hs-form-checkbox .hs-form-checkbox-display:hover span::before,
.hs-form-checkbox .hs-form-booleancheckbox-display:hover span::before,
.hs-form-booleancheckbox .hs-form-checkbox-display:hover span::before,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display:hover span::before {
  background-color: rgba(207, 191, 168, 0.12);
}
.hs-form-checkbox .hs-form-checkbox-display .hs-input:focus ~ span::before,
.hs-form-checkbox .hs-form-booleancheckbox-display .hs-input:focus ~ span::before,
.hs-form-booleancheckbox .hs-form-checkbox-display .hs-input:focus ~ span::before,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input:focus ~ span::before {
  background-color: rgba(207, 191, 168, 0.12);
}
.hs-form-checkbox .hs-form-checkbox-display .hs-input:checked ~ span::before,
.hs-form-checkbox .hs-form-booleancheckbox-display .hs-input:checked ~ span::before,
.hs-form-booleancheckbox .hs-form-checkbox-display .hs-input:checked ~ span::before,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input:checked ~ span::before {
  background-color: #000000;
}
.hs-form-checkbox .hs-form-checkbox-display .hs-input:checked ~ span::after,
.hs-form-checkbox .hs-form-booleancheckbox-display .hs-input:checked ~ span::after,
.hs-form-booleancheckbox .hs-form-checkbox-display .hs-input:checked ~ span::after,
.hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input:checked ~ span::after {
  display: block;
}

.hs-button {
  display: inline-block;
  font-family: "GT America", sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  vertical-align: middle;
  user-select: none;
  background-color: #000000;
  border: 2px solid #000000;
  padding: 10px 20px;
  font-size: 0.75rem;
  line-height: 20px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 1.875rem;
}
@media (prefers-reduced-motion: reduce) {
  .hs-button {
    transition: none;
  }
}
@media (min-width: 1025px) {
  .hs-button {
    margin-top: 3rem;
    font-size: 0.75rem;
  }
}
.hs-button:hover {
  color: #212529;
  text-decoration: none;
  background-color: transparent;
  border-color: #212529;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  padding: 0;
  font-family: "Kokoro", serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.13158;
  color: #000000;
  vertical-align: middle;
  background: transparent url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Theme/small-arrow-black.svg") no-repeat right;
  background-position-x: calc(100% - 11px);
  background-size: 12px;
  border-radius: 0;
  appearance: none;
  border: 2px #000000 solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
@media (min-width: 768px) {
  .custom-select {
    padding: 1.25rem 0 1.25rem;
    height: 4.375rem;
  }
}
.custom-select:focus {
  color: #000000;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}
.custom-select:focus::-ms-value {
  color: #000000;
  background-color: transparent;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}

.form-group-select {
  position: relative;
}
.form-group-select label {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%);
  color: #000000;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .form-group-select label {
    left: 0;
    top: 0;
  }
}