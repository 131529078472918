@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$colors: map-remove($colors, "blue", "indigo", "purple", "pink", "red", "orange", "yellow", "teal", "cyan");
$colors: (
        "gray": $black
);
$theme-colors: map-remove($theme-colors, "info", "warning");

//here because in variables we don't have bootstraps function add
$input-height: 2.5rem;
$input-height-lg: 4.375rem;

//Bootstrap Optional
//@import "bootstrap/scss/forms";--> see custom styles below
//@import "bootstrap/scss/custom-forms"; --> different custom solution below!

@import "../../Libraries/Scss/bootstrap-datepicker";

//
// Textual form controls
//

@mixin custom-form-validation-state($state, $color, $icon) {
    .hs-input {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-image: none;
            background-repeat: no-repeat;
            background-position: right $input-padding-x center;
            background-size: 1rem 1rem;

            @include media-breakpoint-up(md) {
                padding-right: $input-padding-x-lg * 2;
                background-position: right $input-padding-x-lg center;
            }

            &:focus {
                box-shadow: none;
                border-color: $black;
            }

            @if $state == "valid" {
                border-color: $black;
            }

            @if $state == "invalid" {
                ~ label {
                    color: $color !important;
                }
            }
        }
    }

    //// stylelint-disable-next-line selector-no-qualifying-type
    //textarea.form-control {
    //    padding: 20px;
    //
    //    @include media-breakpoint-up(md) {
    //        padding: 50px;
    //    }
    //
    //    @include form-validation-state-selector($state) {
    //        padding-right: $input-padding-x * 2;
    //        background-position: top $input-padding-top right $input-padding-x;
    //
    //        @include media-breakpoint-up(md) {
    //            padding-right: $input-padding-x-lg * 2;
    //            background-position: top $input-padding-top-lg right $input-padding-x-lg;
    //        }
    //    }
    //}

    .custom-select {
        @include form-validation-state-selector($state) {
            padding-right: $custom-select-feedback-icon-padding-right;
            background: $input-bg $custom-select-background no-repeat right;
            background-position-x: calc(100% - 11px);
            background-size: 12px;

            ~ label {
                color: $color;
            }

            &:focus {
                box-shadow: none;
            }

            @if $state == "valid" {
                border-color: $black;

                &:focus {
                    border-color: $black;
                }

                ~ label {
                    color: $black;
                }
            }
        }
    }

    .custom-control-input {
        @include form-validation-state-selector($state) {
            //show only invalid state here.. green checkboxes are ugly..
            @if $state == "valid" {
                ~ .custom-control-label {
                    color: $black;

                    &::before {
                        background-color: transparent !important;
                        border-color: $black !important;
                        box-shadow: none !important;
                    }
                }
            }


            @if $state == "invalid" {
                ~ .custom-control-label {
                    color: $color;
                }
            }
        }
    }
}


.hs-input {
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-top $input-padding-x $input-padding-bottom;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-width: 2px;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);

    @include transition($input-transition);

    @include media-breakpoint-up(md) {
        padding: $input-padding-top-lg $input-padding-x-lg $input-padding-bottom-lg;
        height: $input-height-lg;
    }

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $input-color;
    }

    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        outline: 0;
        box-shadow: none;
    }

    // Placeholder
    &::placeholder {
        color: $input-placeholder-color;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
        background-color: $input-disabled-bg;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &[type="file"] {
        padding-top: $input-padding-bottom;

        @include media-breakpoint-up(md) {
            padding-top: $input-padding-bottom-lg;
        }
    }
}

//textarea.form-control {
//    height: auto;
//    min-height: 19rem;
//    resize: vertical;
//    border: $border-width $border-color solid;
//
//    &::placeholder {
//        font-family: $font-family-sans-serif;
//        text-transform: uppercase;
//
//        @include media-breakpoint-down(sm) {
//            font-size: $font-size-smaller;
//        }
//    }
//}

// Form groups

.hs-form-field {
    margin: ($form-group-margin-bottom/2) 0 ($form-group-margin-bottom/2);
    font-family: $font-family-sans-serif;


    @include media-breakpoint-up(md) {
        margin: ($form-group-margin-bottom-md/2) 0 ($form-group-margin-bottom-md/2);
    }

    &.hs-fieldtype-checkbox,
    &.hs-fieldtype-booleancheckbox {
        margin: 0 0 50px;

        & > label {
            margin: 0 0 25px;
        }
    }
}

.hbspt-form {
    fieldset.form-columns-0,
    fieldset.form-columns-1,
    fieldset.form-columns-2 {
        max-width: none;

        .hs-input {
            display: block;
            width: 100%;
        }
    }
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$form-grid-gutter-width / 2;
    margin-left: -$form-grid-gutter-width / 2;

    > .col,
    > [class*="col-"] {
        padding-right: $form-grid-gutter-width / 2;
        padding-left: $form-grid-gutter-width / 2;
    }

    .form-row-elements {
        display: flex;
        flex-wrap: wrap;
    }
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@each $state, $data in $form-validation-states {
    @include custom-form-validation-state($state, map-get($data, color), map-get($data, icon));
}


// Floating Labels
.hs-fieldtype-select,
.hs-fieldtype-text {
    position: relative;
    label {
        line-height: $input-line-height;
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        position: absolute;
        left: $input-padding-x;
        transition: all 0.15s ease-in-out;
        font-size: $font-size-smallest;
        top: 0;
        transform: translateY(-100%);
        color: $input-color;

        @include media-breakpoint-up(md) {
            left: $input-padding-x-lg;
        }

        @include media-breakpoint-up(md) {
            font-size: $font-size-base;
        }
    }
}

// Custom Checkboxes and Radiobuttons
.inputs-list {
    padding: 0;

    li {
        list-style: none;
    }

    &.hs-error-msgs {
        label,
        label a,
        .hs-error-msg {
            position: relative;
            transform: none;
            margin: 0.75rem 0;
            color: #b60202;
            font-size: 1rem;
            line-height: 1.25;
        }

        label a {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.hs-form-checkbox,
.hs-form-booleancheckbox {
    list-style: none;

    .hs-form-checkbox-display,
    .hs-form-booleancheckbox-display {
        position: relative;
        display: block;
        transform: none;
        z-index: 1;

        .hs-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            z-index: -1; // Put the input behind the label so it doesn't overlay text
        }

        & > span {
            cursor: pointer;
            user-select: none;
            position: relative;
            display: block;
            margin: 0;
            padding-left: $custom-control-gutter + $custom-control-indicator-size;
            font-family: $font-family-serif;
            text-transform: none;
            font-size: $h2-font-size;

            &::before {
                position: absolute;
                content: "";
                display: block;
                top: 50%;
                margin-top: -($custom-control-indicator-size / 2);
                left: 0;
                height: $custom-control-indicator-size;
                width: $custom-control-indicator-size;
                border: solid 2px $black;
                border-radius: $custom-checkbox-indicator-border-radius;

                .frame-background-dark & {
                    background-color: $input-bg;
                }
            }

            &::after {
                position: absolute;
                display: none;
                content: "";
                top: 50%;
                margin-top: -7px;
                width: 6px;
                height: 12px;
                left: 9px;
                border: solid $white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        &:hover span::before {
            background-color: $custom-control-hover-indicator-bg;
        }

        .hs-input:focus ~ span::before {
            background-color: $custom-control-hover-indicator-bg;
        }

        .hs-input:checked ~ span::before {
            background-color: $custom-control-indicator-checked-bg;
        }

        .hs-input:checked ~ span::after {
            display: block;
        }
    }
}

.hs-button {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $white;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    text-transform: uppercase;
    white-space: $btn-white-space;
    letter-spacing: 2px;
    vertical-align: middle;
    user-select: none;
    background-color: $btn-background-color;
    border: $btn-border-width solid $btn-border-color;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);

    margin-top: 1.875rem;
    @include media-breakpoint-up(lg) {
        margin-top: 3rem;
        font-size: $btn-font-size-lg;
    }

    @include hover() {
        color: $body-color;
        text-decoration: none;
        background-color: transparent;
        border-color: $body-color;
    }
}

// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//

.custom-select {
    display: inline-block;
    width: 100%;
    height: $input-height;
    padding: 0;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    vertical-align: middle;
    background: $input-bg $custom-select-background no-repeat right;
    background-position-x: calc(100% - 11px);
    background-size: 12px;
    @include border-radius($input-border-radius, 0);
    appearance: none;
    border: $border-width $border-color solid;
    border-top: 0;
    border-left: 0;
    border-right: 0;

    @include media-breakpoint-up(md) {
        padding: $input-padding-top-lg $input-padding-x-lg $input-padding-bottom-lg;
        height: $input-height-lg;
    }

    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        outline: 0;
        box-shadow: none;

        &::-ms-value {
            // For visual consistency with other platforms/browsers,
            // suppress the default white text on blue background highlight given to
            // the selected option text when the (still closed) <select> receives focus
            // in IE and (under certain conditions) Edge.
            // See https://github.com/twbs/bootstrap/issues/19398.
            color: $input-color;
            background-color: $input-bg;
        }
    }

    // Hides the default caret in IE11
    &::-ms-expand {
        display: none;
    }

    // Remove outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $custom-select-color;
    }
}

.form-group-select {
    position: relative;

    label {
        position: absolute;
        left: $input-padding-x;
        top: 0;
        transform: translateY(-100%);
        color: $input-color;
        font-size: $font-size-smallest;

        @include media-breakpoint-up(md) {
            left: $input-padding-x-lg;
            top: 0;
        }
    }
}
